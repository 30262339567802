import useSWR from 'swr';
import { apirc } from '~/configs/apirc';
export const useEstimatedSettlementPrice = (options, config) => {
    const refreshInterval = config?.refreshInterval || 1000 * 60;
    const { symbol, date } = options;
    const res = useSWR([symbol, date], async () => {
        if (!symbol || !date)
            return;
        const $res = await apirc.marketInsightService.GET('/api/monitor/estimated_settlement_price', {
            params: {
                query: {
                    symbol,
                    date,
                },
            },
        });
        return $res.data;
    }, {
        refreshInterval,
    });
    return res;
};
