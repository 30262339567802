import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { AppLink2 } from '~/components/AppLink2';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import MarqueeSymbolBody from '~/pages/stanli/MarqueeSymbolBody';
const symbols = ['TSEA', 'OTCA', 'TSE13', 'TSE17', '2330', 'TX-1'];
export const Stanli_Menu = memo(function Stanli_Menu() {
    useEffect(() => {
        signalrStore2.addQuote(symbols);
        return () => {
            signalrStore2.removeQuote(symbols);
        };
    }, [JSON.stringify(symbols)]);
    const quote = signalrHooks2.useQuotes(symbols);
    return (<styleds.container>
      <styleds.menuContent>
        <styleds.title>功能選單</styleds.title>
        <AppLink2 href={'/stanli'}>即時指摽</AppLink2>
        <AppLink2 href={'/stanli/option'}>選擇權</AppLink2>
        <AppLink2 href={'/stanli/chips'}>股市儀表</AppLink2>
        <AppLink2 href={'/stanli/monitor'}>市場監控</AppLink2>
      </styleds.menuContent>
      <styleds.quoteContent>
        <styleds.title>常用報價</styleds.title>
        {quote.map((s, index) => {
            return (<div key={index} css={css `
                width: 100%;
                height: 60px;
                padding: 2px 4px;
              `}>
              <MarqueeSymbolBody.Display quote={s}/>
            </div>);
        })}
      </styleds.quoteContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 2px;
    gap: 2px;
  `,
    menuContent: styled.div `        
  ${fill_vertical_all_center};
  height: 180px;
  gap: 2px;
  background-color: #fafafa;
  box-shadow: 0 0 6px 2px #cccccc;
  border-radius: 8px;
  padding: 2px 2px;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    color: #111111;

    &:hover {
      background-color: #eaeaea;
      color: #111111;
      font-size: 18px;
      }
    `,
    quoteContent: styled.div `
    ${fill_vertical_cross_center};
    background-color: #fafafa;
    box-shadow: 0 0 6px 2px #cccccc;
    border-radius: 8px;
    padding: 2px 2px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 24px;
    border-radius: 5px;
    background-color: #dedede;
  `,
};
