import { store } from '~/pages/heineken_template/_private/store';
// import { sungopStock_customized2 } from '~/trades/indicators/sungop_stock/sungopStock_customized2'
import { sungopStock_customized1 } from '~/trades/indicators/sungop_stock/sungopStock_customized1';
import { sungopStock_customized3 } from '~/trades/indicators/sungop_stock/sungopStock_customized3';
import { sungopStock_customized0 } from '~/trades/indicators/sungop_stock/sungopStock_customized0';
import { m168168_foreigner } from '~/trades/indicators/sungop_stock/m168168_foreigner';
export const sungopStock_strategiesGroup = {
    main: [
        {
            displayName: '交易指標',
            indicators: [
                sungopStock_customized0,
                m168168_foreigner,
                sungopStock_customized1,
                // sungopStock_customized2,
                sungopStock_customized3,
            ],
            symbol: 'TSEA',
            interval: '1D',
            panesRatio: 66,
        },
    ],
};
export const sungopStock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[
            sungopStock_customized0,
            m168168_foreigner,
            sungopStock_customized1,
            // sungopStock_customized2,
            sungopStock_customized3,
        ],
    ];
    store.charting.initStrategy({
        configs: [...sungopStock_strategiesGroup.main],
    });
};
