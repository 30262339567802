import styled from '@emotion/styled';
import { Pagination, Tooltip } from '@mantine/core';
import { css } from '@emotion/react';
import React, { memo, useEffect, useState } from 'react';
import { fill_vertical_all_center, fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useStockNewsWallResource, } from '~/modules/information-wall/stock-news-wall/function/useStockNewsWallResource';
import dayAPI from '~/utils/dayAPI';
export const Good178S_StockNewsWall = memo(function Good178S_StockNewsWall(props) {
    const { symbol } = props;
    // 呈現前30筆新聞
    const latestNewsData = useStockNewsWallResource({ symbol })?.item?.slice(0, 30) ?? [];
    //Pagination--
    const [activePage, setPage] = useState(1);
    const maxItemsPerPage = 10;
    /** 更換條件時 將起始值設為1 */
    useEffect(() => {
        setPage(1);
    }, [JSON.stringify(latestNewsData)]);
    /** 起始 */
    const startIndex = (activePage - 1) * maxItemsPerPage;
    /** 終止 */
    const endIndex = startIndex + maxItemsPerPage;
    /** 總頁數 */
    const totalPage = Math.ceil(latestNewsData.length / maxItemsPerPage);
    /** 對應頁數返回的商品列 */
    const slicedItems = latestNewsData.slice(startIndex, endIndex);
    return (<styleds.container>
        <styleds.symbolListContent>
          {latestNewsData.length >= 1 ? (<>
              <NewsTable newsInfo={slicedItems}/>
            </>) : (<styleds.noDataWarning>無資料</styleds.noDataWarning>)}
        </styleds.symbolListContent>
        <styleds.paginationContent>
          <Pagination total={totalPage === 0 ? 1 : totalPage} siblings={0} 
    // 選到非第一頁後更新條件，標的更新，但頁碼仍顯示在原本的頁碼
    value={activePage} onChange={val => setPage(val)}/>
        </styleds.paginationContent>
      </styleds.container>);
});
const NewsTable = memo(function NewsTable(props) {
    const newsData = props.newsInfo;
    return (<styleds.newsTable.container>
      {newsData.map((item, index) => {
            return (<styleds.newsTable.body.container key={index}>
            <styleds.newsTable.date.container>
              <styleds.newsTable.date.header>日期</styleds.newsTable.date.header>
              <styleds.newsTable.date.body>
                {dayAPI(item.pubDate).format('YYYY/MM/DD')}
              </styleds.newsTable.date.body>
            </styleds.newsTable.date.container>

            <styleds.newsTable.link.container>
              <styleds.newsTable.link.header>標題</styleds.newsTable.link.header>
              <styleds.newsTable.link.body>
                <Tooltip multiline width={324} position='top-start' withArrow label={`${item.title}`} color='gray'>
                  <a href={item.link} target='_blank' rel='noreferrer' css={css `
                      white-space: nowrap;
                      overflow: hidden;
                      color: #6788e0;
                    `}>
                    {item.title}
                  </a>
                </Tooltip>
              </styleds.newsTable.link.body>
            </styleds.newsTable.link.container>
          </styleds.newsTable.body.container>);
        })}
    </styleds.newsTable.container>);
});
const styleds = {
    newsTable: {
        container: styled.div `
      width: 100%;
      height: 100%;
    `,
        body: {
            container: styled.div `
        ${fill_vertical_all_center}
        height: 70px;
        width: 100%;
        border-radius: 4px;
        padding: 2px 4px;
        margin: 2px 0;

        border: 1px solid #c7c7c7;
        &:hover {
          background-color: #97969642;
          transition: 0.3s;
        }
        flex-shrink: 0;
      `,
        },
        date: {
            container: styled.div `
        ${fill_horizontal_all_center}
        height: 36px;
        width: 100%;
        border-radius: 4px;
        padding: 2px 4px;

        user-select: none;
      `,
            header: styled.div `
        ${fill_horizontal_all_center};
        border-radius: 4px;
        white-space: nowrap;
        max-width: 40px;
      `,
            body: styled.div `
        height: 100%;
        ${fill_horizontal_all_center};
        justify-content: center;
        border-top-left-radius: 80px 80px;
        border-bottom-left-radius: 80px 80px;
      `,
        },
        link: {
            container: styled.div `
        ${fill_horizontal_all_center}
        height: 36px;
        width: 100%;
        border-radius: 4px;
        padding: 2px 4px;

        user-select: none;
      `,
            header: styled.div `
        ${fill_horizontal_all_center};
        max-width: 40px;
      `,
            body: styled.div `
        ${fill_horizontal_all_center};
        cursor: pointer;
        justify-content: start;
        padding-left: 8px;
        /* 隱藏超出版面的文字 */
        overflow: hidden;
        &:hover {
          text-decoration: underline;
          color: #6788e0;
        }
      `,
        },
    },
    container: styled.div `
    padding: 8px;
    border: 1px solid #acacac;
    border-radius: 4px;
    height: 100%;
    width: 100%;
  `,
    symbolListContent: styled.div `
    ${fill_vertical_all_center};
    ${scrollbar2Css};
    height: calc(100% - 40px);
  `,
    paginationContent: styled.div `
    ${fill_vertical_all_center};
    padding: 4px;
    height: 40px;
  `,
    noDataWarning: styled.div `
    border: 1px solid #e0e0e0;
    padding: 8px;
    ${fill_vertical_all_center}
  `,
};
